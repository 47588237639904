import React          from "react";
import Layout         from "../components/Layout";
import StyledHero     from "../components/StyledHero"
import { graphql }    from "gatsby"
import Contact        from "../components/Contact";
import Seo            from "../components/SEO";




const contact = ({ data }) => 
{
    return (
        <div>
            <Layout>
            <Seo title="Contact"/>
            <StyledHero img={data.connectBcg.childImageSharp.fluid}>
                Contact Pages
            </StyledHero>
            <Contact/>
             </Layout>
        </div>
    )
}
export default contact ;

export const query = graphql`
  {
    connectBcg: file(relativePath: {eq: "connectBcg.jpeg"}) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 4160) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;